import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const AlertGrid = ({ occupancy, filter }) => {

    const gridRef = useRef();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "Alert Type",
            field: "alType",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Alert Date",
            field: "alBegin",
            type: "rightAligned",
        },
        {
            headerName: "Alert Description",
            field: "alText",
            filter: "agTextColumnFilter",
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    const [rowData, setRowData] = useState();

    useEffect(() => {
        const path = "/api/alert/" + occupancy.id;
        API.get("DataApi", path).then((data) => setRowData(data));
    }, [occupancy]);

    const sideBar = {
        defaultToolPanel: '',
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ]
    };

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    return (
        <AgGridReact
            animateRows={true}
            autoGroupColumnDef={autoGroupColumnDef}
            cacheQuickFilter={true}
            checkboxSelection={false}
            className="ag-theme-balham"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupDisplayType={'singleColumn'}
            includeHiddenColumnsInQuickFilter={true}
            onFirstDataRendered={onFirstDataRendered}
            ref={gridRef}
            rowData={rowData}
            rowGroupPanelShow={'always'}
            rowSelection={'single'}
            sideBar={sideBar}
            suppressRowClickSelection={false}
            suppressCellFocus={true}
            suppressRowDeselection={false}
            quickFilterText={filter}
        ></AgGridReact>
    );
};

export default AlertGrid;
