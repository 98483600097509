export const AccountingFormatter = (params) => {
    if (params.value) {
        let options = {
            style: "currency",
            currency: "AUD",
            currencySign: "accounting",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return new Intl.NumberFormat(undefined, options).format(params.value).replace("A$", "");
    }
    return "";
}

export const AccountingStyle = (params) => {
    if (params.value) {
        if (params.value < 0) {
            return { color: 'red' };
        }
        return null;
    }
    return "";
}
