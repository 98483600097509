// Import models
import type {
  BadRequestErrorResponseContent,
  ForbiddenErrorResponseContent,
  InternalFailureErrorResponseContent,
  SignInRequestContent,
  SignUpRequestContent,
  UnauthorizedErrorResponseContent,
  XeroWebhookRequestContent,
} from '../models';
// Import request parameter interfaces
import {
    SignInRequest,
    SignUpRequest,
    XeroWebhookRequest,
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);

/**
 * useMutation hook for the SignIn operation
 */
export const useSignIn = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, SignInRequest>, 'mutationFn'>
): UseMutationResult<void, TError, SignInRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SignInRequest) => api.signIn(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SignUp operation
 */
export const useSignUp = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, SignUpRequest>, 'mutationFn'>
): UseMutationResult<void, TError, SignUpRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SignUpRequest) => api.signUp(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the XeroWebhook operation
 */
export const useXeroWebhook = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, XeroWebhookRequest>, 'mutationFn'>
): UseMutationResult<void, TError, XeroWebhookRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: XeroWebhookRequest) => api.xeroWebhook(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

