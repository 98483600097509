import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Form } from "react-bootstrap";
import { API } from 'aws-amplify';
import { useParams, useSearchParams } from "react-router-dom";
import QuickFilter from "../../components/QuickFilter";
import ChargeGrid from "../../components/Occupancy/ChargeGrid";
import ReviewGrid from "../../components/Occupancy/ReviewGrid";
import SalesGrid from "../../components/Occupancy/SalesGrid";
import BillingGrid from "../../components/Occupancy/BillingGrid";
import Loading from "../../components/Loading";
import ChargeForm from "./ChargeForm";
import { useAuthenticator } from '@aws-amplify/ui-react';
import RelatedOccupancyGrid from "../../components/Occupancy/RelatedOccupancyGrid";
import UserDataGrid from "../../components/Occupancy/UserDataGrid";
import AlertGrid from "../../components/Occupancy/AlertGrid";
import TermGrid from "../../components/Occupancy/TermGrid";

const OccupancyDetail = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get("filter") ?? "");
  const [occupancy, setOccupancy] = useState();
  const [activeView, setActiveView] = useState();
  const [loadedViewTypes, setLoadedViewTypes] = useState([]);
  const [activeCharge, setActiveCharge] = useState();

  const dateFormatter = (params) => {
    if (params.value)
      return new Date(params.value).toLocaleDateString();
    return "";
  }

  let { id: occupancyId } = useParams();

  const loadViewType = (type) => {
    let _type = type ?? "charge";
    setActiveView(_type);
    setLoadedViewTypes(v => {
      if (!v.includes(_type)) {
        return v.concat([_type]);
      }
      else {
        return v;
      }
    });
  };

  const setViewType = useCallback((type) => {
    loadViewType(type);
    searchParams.set("type", type);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let type = searchParams.get("type");
    loadViewType(type);
    if (!occupancy || occupancy.id !== occupancyId) {
      API.get("DataApi", "/api/occupancy/" + occupancyId).then((data) => setOccupancy(data));
    }
  }, [occupancyId, occupancy, searchParams]);

  const editChargeHandler = useCallback((charge) => {
    setActiveCharge(charge);
  }, []);

  const viewBillingsHandler = useCallback((charge) => {
    setFilter(charge.id);
    setViewType("billing");
  }, [setViewType]);

  const renderChargeTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("charge")) {
        return (
          <ChargeGrid occupancy={occupancy} filter={filter} editCharge={editChargeHandler} viewBillings={viewBillingsHandler} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter, editChargeHandler, viewBillingsHandler]);

  const renderReviewTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("review")) {
        return (
          <ReviewGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderSalesTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("sales")) {
        return (
          <SalesGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderBillingTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("billing")) {
        return (
          <BillingGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderRelatedOccupancyTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("related")) {
        return (
          <RelatedOccupancyGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderUserDataTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("userdata")) {
        return (
          <UserDataGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderAlertTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("alert")) {
        return (
          <AlertGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  const renderTermTab = useCallback(() => {
    if (occupancy) {
      if (loadedViewTypes.includes("term")) {
        return (
          <TermGrid occupancy={occupancy} filter={filter} />
        )
      }
    }
  }, [loadedViewTypes, occupancy, filter]);

  return (
    <>
      {authStatus === "authenticated" && occupancy ? (
        <Container fluid className="d-flex flex-column p-0">
          <Row className="m-0 p-2">
            <Col className="d-flex flex-column m-0">
              <QuickFilter heading={occupancy.ocName + " (" + occupancy.ocStatus + ")"} filterValue={filter} filterHandler={setFilter} />
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBegin">
                    <Form.Label column="sm">Begin</Form.Label>
                    <Form.Control size="sm" placeholder="Begin" readOnly value={dateFormatter({ value: occupancy.ocBegin })} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formEnd">
                    <Form.Label column="sm">End</Form.Label>
                    <Form.Control size="sm" placeholder="End" readOnly value={dateFormatter({ value: occupancy.ocEnd })} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formBillingTenant">
                    <Form.Label column="sm">Billing Tenant</Form.Label>
                    <Form.Control size="sm" placeholder="Billing Tenant" readOnly value={occupancy.bcName} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formLegalTenant">
                    <Form.Label column="sm">Legal Tenant</Form.Label>
                    <Form.Control size="sm" placeholder="Legal Tenant" readOnly value={occupancy.lcName} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBuilding">
                    <Form.Label column="sm">Building</Form.Label>
                    <Form.Control size="sm" placeholder="Building" readOnly value={occupancy.buName} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formLevel">
                    <Form.Label column="sm">Level</Form.Label>
                    <Form.Control size="sm" placeholder="Level" readOnly value={occupancy.blName} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formSpace">
                    <Form.Label column="sm">Space</Form.Label>
                    <Form.Control size="sm" placeholder="Space" readOnly value={occupancy.spName} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formType">
                    <Form.Label column="sm">Type</Form.Label>
                    <Form.Control size="sm" placeholder="Type" readOnly value={occupancy.ocType} />
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row className="flex-fill h-75 m-0 p-2">
            <Col className="d-flex flex-column">
              <Tab.Container defaultActiveKey={loadedViewTypes[0]} activeKey={activeView}>
                <Nav variant="tabs" onSelect={(k) => setViewType(k)}>
                  <Nav.Item>
                    <Nav.Link eventKey="charge">Charges</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="billing">Billings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="review">Reviews</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sales">Sales</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="related">Related Occupancies</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="userdata">User Data</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="alert">Alerts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="term">Terms</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="d-flex flex-fill m-2">
                  <Tab.Pane eventKey="charge">
                    {renderChargeTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="billing">
                    {renderBillingTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="review">
                    {renderReviewTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="sales">
                    {renderSalesTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="related">
                    {renderRelatedOccupancyTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="userdata">
                    {renderUserDataTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="alert">
                    {renderAlertTab()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="term">
                    {renderTermTab()}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              <ChargeForm charge={activeCharge} handleClose={() => setActiveCharge(null)} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default OccupancyDetail;
