import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import QuickFilter from "../components/QuickFilter";
import Loading from "../components/Loading";
import { API } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UserGrid from "../components/UserGrid";
import UserInviteForm from "../components/UserInviteForm";

export default function Users() {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get("filter") ?? "");
  const [inviteUser, setInviteUser] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState();

  const userInviteFormCloseHandler = useCallback((users) => {
    setInviteUser(false);
    if (users) {
      setInvitedUsers(users);
    }
  }, []);

  return (
    <>
      {authStatus === "authenticated" ? (
        <Row className="flex-fill m-0 p-2">
          <Col className="d-flex flex-column m-0">
            <QuickFilter heading={"Users"} filterValue={filter} filterHandler={setFilter} />
            <Row className="m-0 p-1">
              <Col>
                <Button onClick={() => setInviteUser(true)}>Invite User</Button>
              </Col>
            </Row>
            <UserGrid filter={filter} invitedUsers={invitedUsers} />
            <UserInviteForm inviteUser={inviteUser} handleClose={userInviteFormCloseHandler} />
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </>
  );
};

export const userInvite = async ({ request }) => {
  const data = await request.formData();
  
  const payload = {
    emailAddresses: data.get("emailAddresses"),
    administrator: data.has("administrator"),
    power: data.has("power"),
    standard: data.has("standard"),
  }

  var response = {};

  try {
    response = await API.post("SecurityApi", "/api/auth/user", { body: payload });
    response.success = true;
  } catch (error) {
    response.message = error.response.data.message;
    response.success = false;
  }

  return response;
}