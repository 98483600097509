import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useNavigate } from "react-router";

const RelatedOccupancyGrid = ({ occupancy, filter }) => {

    const gridRef = useRef();
    const nav = useNavigate();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "Name",
            field: "ocName",
            filter: "agTextColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
        },
        {
            headerName: "Building",
            field: "buName",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            rowGroup: false,
        },
        {
            headerName: "Level",
            field: "blName",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            rowGroup: false,
        },
        {
            headerName: "Space",
            field: "spName",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            rowGroup: false,
        },
        {
            headerName: "Contacts",
            children: [
                {
                    headerName: "Billing",
                    field: "bcName",
                    filter: "agTextColumnFilter",
                    enableRowGroup: true,
                    hide: true,
                },
                {
                    headerName: "Legal",
                    field: "lcName",
                    filter: "agTextColumnFilter",
                    enableRowGroup: true,
                },
            ]
        },
        {
            headerName: "Occupancy",
            children: [
                {
                    headerName: "Begin",
                    field: "ocBegin",
                    type: "rightAligned",
                },
                {
                    headerName: "End",
                    field: "ocEnd",
                    type: "rightAligned",
                },
            ]
        },
        {
            headerName: "Status",
            field: "ocStatus",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Type",
            field: "ocType",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    const [rowData, setRowData] = useState();

    useEffect(() => {
        const path = "/api/occupancy/related/" + occupancy.id + "/" + occupancy.lcId;
        API.get("DataApi", path).then((data) => setRowData(data));
    }, [occupancy]);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            nav("/occupancy/" + selectedRows[0].id, { state: { id: selectedRows[0].id } });
        }
    };

    const sideBar = useMemo(() => {
        return {
            defaultToolPanel: '',
            hiddenByDefault: false,
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ]
        };
    }, []);

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'ocName', sort: 'asc', sortIndex: 0 },
                { colId: 'ocBegin', sort: 'desc', sortIndex: 1 },
            ]
        });
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    return (
        <>
            <AgGridReact
                animateRows={true}
                autoGroupColumnDef={autoGroupColumnDef}
                cacheQuickFilter={true}
                checkboxSelection={false}
                className="ag-theme-balham"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                groupDisplayType={'singleColumn'}
                includeHiddenColumnsInQuickFilter={true}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                ref={gridRef}
                rowData={rowData}
                rowGroupPanelShow={'always'}
                rowSelection={'single'}
                sideBar={sideBar}
                suppressRowClickSelection={false}
                suppressCellFocus={true}
                suppressRowDeselection={false}
                quickFilterText={filter}
            ></AgGridReact>
        </>
    );
};

export default RelatedOccupancyGrid;
