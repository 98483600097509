import { useNavigate } from "react-router-dom";
import { Auth, API, Hub, Logger } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Loading from '../components/Loading';
import { useSignIn, useSignUp } from "xoura-api-typescript-react-query-hooks";

const SignIn = () => {

  const logger = new Logger('xoura-website-signin');
  const nav = useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const signIn = useSignIn();
  const signUp = useSignUp();

  async function signUpApi() {
    if (authStatus === "configuring") {
      logger.info("Sign up");
      Auth.currentCredentials()
        .then(credentials => {
          signUp.mutateAsync({
            signUpRequestContent: {
              identityId: credentials.identityId,
            },
          }).then(_ => {
            Auth.currentAuthenticatedUser().then((user) => {
              Auth.updateUserAttributes(user, {});
              nav("/dash");
            })
          });
        });
    }
  }

  async function signInApi() {
    if (authStatus === "configuring") {
      logger.info("Sign in");
      Auth.currentCredentials()
        .then(credentials => {
          signIn.mutateAsync({
            signInRequestContent: {
              identityId: credentials.identityId,
            },
          }).then(_ => {
            Auth.currentAuthenticatedUser().then((user) => {
              Auth.updateUserAttributes(user, {});
              nav("/dash");
            });
          });
        });
    }
  }

  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case "customOAuthState":
        switch (data) {
          case "XeroSignUp":
            signUpApi();
            break;
          case "XeroSignIn":
            signInApi();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  });

  return (
    <Loading />
  );
};

export default SignIn;
