import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Card, Button, CardGroup, Col } from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { BoxArrowInRight } from "react-bootstrap-icons";
import QuickFilter from "../components/QuickFilter";
import { useAuthenticator } from '@aws-amplify/ui-react';

const Dashboard = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get("filter") ?? "");

  return (
    <>
      {authStatus === "authenticated" ? (
        <Row className="flex-fill m-0 p-2">
          <Col className="d-flex flex-column m-0">
            <QuickFilter heading={"Dashboard"} filterValue={filter} filterHandler={setFilter} />
            <p>{filter}</p>
          </Col>
        </Row>
      ) : (
        <CardGroup>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>
                To use Xoura you must have a Xero login, and provide access to a Xero organisation.
              </Card.Text>
              <Card.Text>
                Click Sign Up below to get started.
              </Card.Text>
              <Button variant="success" size="sm" className="d-flex align-items-center" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignUp", customState: "XeroSignUp" })}>Sign Up</Button>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Sign In</Card.Title>
              <Card.Text>
                Click Sign In to login to an existing account.
              </Card.Text>
              <Button variant="outline-primary" size="sm" className="d-flex align-items-center" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignIn", customState: "XeroSignIn" })}>Sign In&nbsp;<BoxArrowInRight /></Button>
            </Card.Body>
          </Card>
        </CardGroup>
      )}
    </>
  );
};

export default Dashboard;
