import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';

const Selector = () => {
  const { authStatus, user } = useAuthenticator(context => [context.authStatus, context.user]);
  const [title, setTitle] = useState();
  const [organisations, setOrganisations] = useState([]);
  const nav = useNavigate();

  async function selectOrganisation(id) {
    Auth.updateUserAttributes(user, { "custom:active_short_code": id }).then(_ => {
      nav("/dash");
    });
  }

  useEffect(() => {
    async function getOrganisations() {
      // API
      //   .get("SecurityApi", "/api/organisation", { response: false })
      //   .then(data => {
      //     var _orgs = data.map(c => (
      //       { id: c.shortCode, name: c.shortCode }
      //     ));
      //     setOrganisations(_orgs);
      //   })
    }

    if (user && user.attributes["custom:active_short_code"]) {
      if (!organisations.length) {
        getOrganisations();
      }
      else {
        if (authStatus === "authenticated") {
          setTitle(organisations.find(o => o.id === user.attributes["custom:active_short_code"])?.name);
        }
      }  
    }
  }, [organisations, authStatus, user]);

  return (
    <>
      {authStatus === "authenticated" ? (
        <Nav className="justify-content-end">
          <NavDropdown title={title} className="pe-3">
            {organisations.map(o => (
              <NavDropdown.Item key={o.id} onClick={() => selectOrganisation(o.id)}>{o.name}</NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav >
      ) : (<></>)}
    </>
  );
};

export default Selector;
