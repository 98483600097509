import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const SalesGrid = ({ occupancy, filter }) => {

    const gridRef = useRef();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "Id",
            field: "id",
            hide: true,
        },
        {
            headerName: "Category",
            field: "scName",
            filter: "agSetColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
            enablePivot: true,
        },
        {
            headerName: "Group",
            field: "sgName",
            filter: "agSetColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
            enablePivot: true,
        },
        {
            headerName: "Type",
            field: "saType",
            filter: "agSetColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
            enablePivot: true,
        },
        {
            headerName: "Date",
            field: "saDate",
            type: "rightAligned",
            enablePivot: true,
        },
        {
            headerName: "Amount",
            field: "saAmount",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
            aggFunc: "sum",
        },
        {
            headerName: "Amount m2",
            field: "saAmountByArea",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
            aggFunc: "sum",
        },
        {
            headerName: "MAT",
            field: "saMat",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
            aggFunc: "sum",
        },
        {
            headerName: "MAT m2",
            field: "saMatByArea",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
            aggFunc: "sum",
        },
        {
            headerName: "Area",
            field: "saArea",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
            aggFunc: "sum",
        },
        {
            headerName: "Status",
            field: "saStatus",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            enablePivot: true,
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    const [rowData, setRowData] = useState();

    useEffect(() => {
        const path = "/api/sales/" + occupancy.id;
        API.get("DataApi", path).then((data) => setRowData(data));
    }, [occupancy]);

    const sideBar = {
        defaultToolPanel: '',
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ]
    };

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.api.setFilterModel({
            saStatus: {
                values: [
                    "Completed",
                    "Requested",
                    "Waiting",
                ]
            }
        });
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'saDate', sort: 'desc', sortIndex: 0 },
            ]
        });
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    return (
        <AgGridReact
            animateRows={true}
            autoGroupColumnDef={autoGroupColumnDef}
            cacheQuickFilter={true}
            checkboxSelection={false}
            className="ag-theme-balham"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupDisplayType={'singleColumn'}
            includeHiddenColumnsInQuickFilter={true}
            onFirstDataRendered={onFirstDataRendered}
            ref={gridRef}
            rowData={rowData}
            rowGroupPanelShow={'always'}
            rowSelection={'single'}
            sideBar={sideBar}
            suppressRowClickSelection={false}
            suppressCellFocus={true}
            suppressRowDeselection={false}
            quickFilterText={filter}
        ></AgGridReact>
    );
};

export default SalesGrid;
