/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  BadRequestErrorResponseContent,
  ForbiddenErrorResponseContent,
  InternalFailureErrorResponseContent,
  SignInRequestContent,
  SignUpRequestContent,
  UnauthorizedErrorResponseContent,
  XeroWebhookRequestContent,
} from '../models';
import {
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    ForbiddenErrorResponseContentFromJSON,
    ForbiddenErrorResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    SignInRequestContentFromJSON,
    SignInRequestContentToJSON,
    SignUpRequestContentFromJSON,
    SignUpRequestContentToJSON,
    UnauthorizedErrorResponseContentFromJSON,
    UnauthorizedErrorResponseContentToJSON,
    XeroWebhookRequestContentFromJSON,
    XeroWebhookRequestContentToJSON,
} from '../models';

export interface SignInRequest {
    signInRequestContent: SignInRequestContent;
}

export interface SignUpRequest {
    signUpRequestContent: SignUpRequestContent;
}

export interface XeroWebhookRequest {
    xeroWebhookRequestContent: XeroWebhookRequestContent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     * 
     */
    async signInRaw(requestParameters: SignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signInRequestContent === null || requestParameters.signInRequestContent === undefined) {
            throw new runtime.RequiredError('signInRequestContent','Required parameter requestParameters.signInRequestContent was null or undefined when calling signIn.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/security/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestContentToJSON(requestParameters.signInRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async signIn(requestParameters: SignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signInRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async signUpRaw(requestParameters: SignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signUpRequestContent === null || requestParameters.signUpRequestContent === undefined) {
            throw new runtime.RequiredError('signUpRequestContent','Required parameter requestParameters.signUpRequestContent was null or undefined when calling signUp.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/security/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpRequestContentToJSON(requestParameters.signUpRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async signUp(requestParameters: SignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signUpRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async xeroWebhookRaw(requestParameters: XeroWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xeroWebhookRequestContent === null || requestParameters.xeroWebhookRequestContent === undefined) {
            throw new runtime.RequiredError('xeroWebhookRequestContent','Required parameter requestParameters.xeroWebhookRequestContent was null or undefined when calling xeroWebhook.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/webhooks/xero`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: XeroWebhookRequestContentToJSON(requestParameters.xeroWebhookRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async xeroWebhook(requestParameters: XeroWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.xeroWebhookRaw(requestParameters, initOverrides);
    }

}

