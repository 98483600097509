import { Card, Button, CardGroup } from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { BoxArrowInRight } from "react-bootstrap-icons";

const Landing = () => {
  return (
    <CardGroup>
      <Card style={{ width: '30rem' }}>
        <Card.Body>
          <Card.Title>Sign Up</Card.Title>
          <Card.Text>
            To use Xoura you must have a Xero login, and provide access to a Xero organisation.
          </Card.Text>
          <Card.Text>
            Click Sign Up below to get started.
          </Card.Text>
          <Button variant="success" size="sm" className="d-flex align-items-center" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignUp", customState: "XeroSignUp" })}>Sign Up</Button>
        </Card.Body>
      </Card>
      <Card style={{ width: '30rem' }}>
        <Card.Body>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>
            Click Sign In to login to an existing account.
          </Card.Text>
          <Button variant="outline-primary" size="sm" className="d-flex align-items-center" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignIn", customState: "XeroSignIn" })}>Sign In&nbsp;<BoxArrowInRight /></Button>
        </Card.Body>
      </Card>
    </CardGroup>
  );
};

export default Landing;
