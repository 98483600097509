import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Button } from "react-bootstrap";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const ChargeGrid = ({ occupancy, filter, editCharge, viewBillings }) => {

    const gridRef = useRef();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "Actions",
            children: [
                {
                    resizable: false,
                    sortable: false,
                    filter: false,
                    suppressMenu: true,
                    suppressMovable: true,
                    maxWidth: 50,
                    cellRenderer: params => {
                        return <Button variant="link" size="sm" className="m-0 p-0" onClick={() => editCharge(params.data)}>Edit</Button>;
                    }
                },
                {
                    resizable: false,
                    sortable: false,
                    filter: false,
                    suppressMenu: true,
                    suppressMovable: true,
                    maxWidth: 65,
                    cellRenderer: params => {
                        return <Button variant="link" size="sm" className="m-0 p-0" onClick={() => viewBillings(params.data)}>Billings</Button>;
                    }
                }
            ]
        },
        {
            headerName: "Id",
            field: "id",
            hide: true,
        },
        {
            headerName: "Item",
            field: "itName",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "ChargeBasis",
            field: "chBasis",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Status",
            field: "chStatus",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Amount",
            field: "chAmount",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
        },
        {
            headerName: "Charge Begin",
            field: "chBegin",
            type: "rightAligned",
        },
        {
            headerName: "Charge End",
            field: "chEnd",
            type: "rightAligned",
        },
        {
            headerName: "Charged To",
            field: "chChargedTo",
            type: "rightAligned",
        },
        {
            headerName: "Type",
            field: "chType",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Includes Tax",
            field: "chIncTax",
            hide: true,
        },
        {
            headerName: "Is Closed",
            field: "chIsClosed",
            hide: true,
        },
        {
            headerName: "Is Voided",
            field: "chIsVoided",
            hide: true,
        },
        {
            headerName: "Contacts",
            children: [
                {
                    headerName: "Billing",
                    field: "bcName",
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "Legal",
                    field: "lcName",
                    filter: "agTextColumnFilter",
                    hide: true,
                },
            ]
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    const [rowData, setRowData] = useState();

    useEffect(() => {
        const path = "/api/charge/" + occupancy.id;
        API.get("DataApi", path).then((data) => setRowData(data));
    }, [occupancy]);

    const sideBar = {
        defaultToolPanel: '',
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ]
    };

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'chStatus', sort: 'asc', sortIndex: 0 },
                { colId: 'chBegin', sort: 'desc', sortIndex: 1 },
            ]
        });
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    const rowClassRules = useMemo(() => {
        return {
          "ag-row-voided": (params) => {
            return params.data.chIsVoided;
          },
        };
      }, []);

    return (
        <AgGridReact
            animateRows={true}
            autoGroupColumnDef={autoGroupColumnDef}
            cacheQuickFilter={true}
            checkboxSelection={false}
            className="ag-theme-balham"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupDisplayType={'singleColumn'}
            includeHiddenColumnsInQuickFilter={true}
            onFirstDataRendered={onFirstDataRendered}
            ref={gridRef}
            rowClassRules={rowClassRules}
            rowData={rowData}
            rowGroupPanelShow={'always'}
            rowSelection={'single'}
            sideBar={sideBar}
            suppressRowClickSelection={false}
            suppressCellFocus={true}
            suppressRowDeselection={false}
            quickFilterText={filter}
        ></AgGridReact>
    );
};

export default ChargeGrid;
