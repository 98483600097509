import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router";
import AnonHeader from "../components/AnonHeader";
import AnonFooter from "../components/AnonFooter";

export default function AnonLayout() {
    return (
        <>
            <Row className="m-0">
                <Col className="p-0">
                    <AnonHeader />
                </Col>
            </Row>
            <Row className="flex-fill m-0">
                <Col className="d-flex p-4 justify-content-center">
                    <Outlet />
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0">
                    <AnonFooter />
                </Col>
            </Row>
        </>
    );
}