import React from "react";
import { Nav, Button } from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { BoxArrowInRight, BoxArrowRight } from "react-bootstrap-icons";
import { useAuthenticator } from "@aws-amplify/ui-react";

const NavAuth = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  return (
    <>
      {authStatus === "authenticated" ? (
        <Nav className="justify-content-end">
          <Nav.Item className="d-flex align-items-center" >
            <Button variant="outline-primary" size="sm" loadingtext="" onClick={() => Auth.signOut()} aria-label="">Sign Out&nbsp;<BoxArrowRight /></Button>
          </Nav.Item>
        </Nav>
      ) : (
        <Nav className="justify-content-end">
          <Nav.Item className="d-flex align-items-center pe-3" >
            <Button variant="success" size="sm" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignUp", customState: "XeroSignUp" })}>Sign Up</Button>
          </Nav.Item>
          <Nav.Item className="d-flex align-items-center" >
            <Button variant="outline-primary" size="sm" onClick={() => Auth.federatedSignIn({ customProvider: "XeroSignIn", customState: "XeroSignIn" })}>Sign In&nbsp;<BoxArrowInRight /></Button>
          </Nav.Item>
        </Nav>
      )}
    </>
  );
};

export default NavAuth;
