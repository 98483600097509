import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const UserGrid = ({ filter, invitedUsers }) => {

    const gridRef = useRef();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "User ID",
            field: "id",
            filter: "agTextColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: false,
        },
        {
            headerName: "First Name",
            field: "firstName",
            filter: "agTextColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: false,
        },
        {
            headerName: "Last Name",
            field: "lastName",
            filter: "agTextColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: false,
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    useEffect(() => {
        if (invitedUsers) {
            invitedUsers.forEach(user => {
                const rowData = [];
                gridRef.current.api.forEachNode(function (node) {
                  rowData.push(node.data);
                });
                if (rowData.some(e => e.id === user.id)) {
                    gridRef.current.api.applyTransaction({ update: [user] });
                }
                else {
                    gridRef.current.api.applyTransaction({ add: [user] });
                }            
            });    
        }
    }, [invitedUsers]);

    const onGridReady = useCallback(async () => {
        let data = await API.get("SecurityApi", "/api/auth/users");
        gridRef.current.api.setRowData(data);
    }, []);

    const sideBar = {
        defaultToolPanel: '',
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ]
    };

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    const getRowId = useMemo(() => {
        return (params) => params.data.id;
    }, []);

    return (
        <AgGridReact
            animateRows={true}
            autoGroupColumnDef={autoGroupColumnDef}
            cacheQuickFilter={true}
            checkboxSelection={false}
            className="ag-theme-balham"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            groupDisplayType={'singleColumn'}
            includeHiddenColumnsInQuickFilter={true}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            ref={gridRef}
            rowGroupPanelShow={'always'}
            rowSelection={'single'}
            sideBar={sideBar}
            suppressRowClickSelection={false}
            suppressCellFocus={true}
            suppressRowDeselection={false}
            quickFilterText={filter}
        ></AgGridReact>
    );
};

export default UserGrid;
