import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const BillingGrid = ({ occupancy, filter }) => {

    const gridRef = useRef();

    const [defaultColDef] = React.useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    const [columnDefs] = React.useState([
        {
            headerName: "Account",
            field: "acName",
            filter: "agSetColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
        },
        {
            headerName: "Billing Contact",
            field: "bcName",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Type",
            field: "biType",
            filter: "agSetColumnFilter",
            headerCheckboxSelection: false,
            checkboxSelection: false,
            enableRowGroup: true,
        },
        {
            headerName: "Begin",
            field: "biBegin",
            type: "rightAligned",
        },
        {
            headerName: "End",
            field: "biEnd",
            type: "rightAligned",
        },
        {
            headerName: "Status",
            field: "biStatus",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        },
        {
            headerName: "Amount",
            field: "liAmount",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
        },
        {
            headerName: "Tax",
            field: "liTax",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
        },
        {
            headerName: "Total",
            field: "liTotal",
            filter: "agNumberColumnFilter",
            valueFormatter: AccountingFormatter,
            cellStyle: AccountingStyle,
            type: "numericColumn",
        },
        {
            headerName: "Charge Id",
            field: "chId",
            hide: true,
        },
    ]);

    const autoGroupColumnDef = useMemo(() => {
        return {
        };
    }, []);

    const [rowData, setRowData] = useState();

    useEffect(() => {
        const path = "/api/billing/" + occupancy.id;
        API.get("DataApi", path).then((data) => setRowData(data));
    }, [occupancy]);

    const sideBar = {
        defaultToolPanel: '',
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ]
    };

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.columnApi.applyColumnState({
            state: [
                { colId: 'biBegin', sort: 'desc', sortIndex: 0 },
            ]
        });
        gridRef.current.columnApi.autoSizeAllColumns();
    }, []);

    return (
        <AgGridReact
            animateRows={true}
            autoGroupColumnDef={autoGroupColumnDef}
            checkboxSelection={false}
            cacheQuickFilter={true}
            className="ag-theme-balham"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupDisplayType={'singleColumn'}
            includeHiddenColumnsInQuickFilter={true}
            onFirstDataRendered={onFirstDataRendered}
            ref={gridRef}
            rowData={rowData}
            rowGroupPanelShow={'always'}
            rowSelection={'single'}
            sideBar={sideBar}
            suppressRowClickSelection={false}
            suppressCellFocus={true}
            suppressRowDeselection={false}
            quickFilterText={filter}
        ></AgGridReact>
    );
};

export default BillingGrid;
