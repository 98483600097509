import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Form, Row, ButtonGroup, ButtonToolbar, Button } from 'react-bootstrap';

const OccupancyToolPanel = (props) => {
    const [occupancy, setOccupancy] = useState();

    const occupancySelected = (e) => {
        if (e.node.selected) {
            setOccupancy(e.data);
            e.api.openToolPanel("occupancy");
        }
    };

    useEffect(() => {
        props.api.addEventListener('rowSelected', occupancySelected);
        return () => props.api.removeEventListener('rowSelected', occupancySelected);
    }, [props]);

    return (
        <>
            {occupancy ? (
                <Row className="flex-fill m-0 p-0">
                    <Form className="p-1">
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="occupancyName">
                            <Form.Label><FontAwesomeIcon icon={faRoute} /> Navigate</Form.Label>
                            <ButtonToolbar>
                                <ButtonGroup size="sm" className='d-flex align-items-stretch'>
                                    <Button variant="link" onClick={() => props.selectOccupancy(occupancy, "charge")}>Charges</Button>
                                    <Button variant="link" onClick={() => props.selectOccupancy(occupancy, "review")}>Reviews</Button>
                                    <Button variant="link" onClick={() => props.selectOccupancy(occupancy, "sales")}>Sales</Button>
                                    <Button variant="link" onClick={() => props.selectOccupancy(occupancy, "billing")}>Billings</Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Label>{occupancy.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="occupancyBegin">
                            <Form.Label>Begin</Form.Label>
                            <Form.Label>{occupancy.occupancyBegin}</Form.Label>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="occupancyEnd">
                            <Form.Label>End</Form.Label>
                            <Form.Label>{occupancy.occupancyEnd}</Form.Label>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Label>{occupancy.status}</Form.Label>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="defaultChargeType">
                            <Form.Label>Default Charge Type</Form.Label>
                            <Form.Label>{occupancy.defaultChargeType}</Form.Label>
                        </Form.Group>
                    </Form>
                </Row>
            ) : (
                <Row className="flex-fill m-0 p-0">
                    <Form className="p-1">
                        <Form.Group className="d-flex flex-column flex-fill mb-3" controlId="name">
                            <Form.Label>Select an Occupancy</Form.Label>
                            <Form.Label></Form.Label>
                        </Form.Group>
                    </Form>
                </Row>
            )}
        </>
    );
};

export default OccupancyToolPanel;
