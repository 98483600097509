import React, { useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { AppContext } from "./AppContext";
import { nanoid } from "nanoid";
import './App.css';

// amplify
import { Amplify, Auth, Hub, PubSub } from 'aws-amplify';
import { AWSIoTProvider, CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';

// layouts
import AnonLayout from './layouts/AnonLayout';
import AuthLayout from './layouts/AuthLayout';
import RootLayout from './layouts/RootLayout';

// pages
import Dashboard from "./pages/Dashboard";
import Landing from './pages/Landing';
import Occupancy from './pages/Occupancy/Occupancy';
import OccupancyDetail from './pages/Occupancy/OccupancyDetail';
import SignIn from "./pages/SignIn";
import Users, { userInvite } from './pages/Users';
import ApiExplorer from './pages/ApiExplorer';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="" element={<AnonLayout />}>
        <Route exact index element={<Landing />} />
        <Route exact path="signin" element={<SignIn />} />
      </Route>
      <Route path="" element={<AuthLayout />}>
        <Route exact path="dash" element={<Dashboard />} />
        <Route exact path="occupancy">
          <Route exact index element={<Occupancy />} />
          <Route exact path=":id" element={<OccupancyDetail />} />
        </Route>
        <Route exact path="users">
          <Route exact index element={<Users />} action={userInvite} />
        </Route>
        <Route exact path="api" element={<ApiExplorer />} />
      </Route>
    </Route>
  )
);

export default function App() {
  // useEffect(() => {
  //   Auth.currentCredentials()
  //     .then(user => {

  //       Amplify.addPluggable(
  //         new AWSIoTProvider({
  //           aws_pubsub_region: process.env.REACT_APP_REGION,
  //           aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
  //           clientId: `${user.identityId}-${nanoid()}`,
  //         })
  //       );

  //       PubSub.subscribe(['dev/bananas', 'dev/apples']).subscribe({
  //         next: data => console.log('Message received', data),
  //         error: error => console.error(error),
  //         complete: () => console.log('Done'),
  //       });

  //       Hub.listen('pubsub', (data) => {
  //         const { payload } = data;
  //         if (payload.event === CONNECTION_STATE_CHANGE) {
  //           const connectionState = payload.data.connectionState;
  //           console.log(connectionState);
  //         }
  //       });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  return (
    (
      <AppContext.Provider value={{}}>
        <RouterProvider router={router} />
      </AppContext.Provider>
    )
  );
}
