import React, { useEffect, useState } from "react";
import { Form, useActionData } from "react-router-dom";
import { Modal, Button, FormGroup, FormLabel, FormControl, FormCheck, Alert, FormText } from "react-bootstrap";

export default function UserInviteForm({ inviteUser, handleClose }) {
    const [message, setMessage] = useState();

    const result = useActionData();

    useEffect(() => {
        if (result) {
            if (result.success) {
                setMessage(null);
                handleClose(result.data);
            }
            else
            {
                setMessage(result.message);
            }
        }
    }, [result, handleClose]);

    return (
        <Modal show={inviteUser} onHide={handleClose}>
            <Form method="post">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Invite User/s
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup className="mb-3" controlId="formEmailAddresses">
                        <FormLabel>Email Addresses</FormLabel>
                        <FormControl size="sm" placeholder="Email Addresses" type="email" multiple name="emailAddresses" required aria-describedby="emailAddressesText" />
                        <FormText id="emailAddressesText" muted>Enter multiple email addresses separated by a comma</FormText>
                    </FormGroup>
                    {message ? <Alert variant="danger">{message}</Alert> : <></>}
                    <FormGroup className="mb-3" controlId="formGroups">
                        <FormLabel>Groups</FormLabel>
                        <FormCheck size="sm" label="Administrator" type="checkbox" name="administrator" />
                        <FormCheck size="sm" label="Power" type="checkbox" name="power" />
                        <FormCheck size="sm" label="Standard" type="checkbox" name="standard" />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
